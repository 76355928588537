@use "styles/mixins/setFonts" as *;


.departments {
  padding: 15px;
  max-width: 1470px;
  margin: 0 auto;

  &__header {
    @include setFonts("Roboto", 2.625rem, 2.75rem, 700);
    text-align: center;
    color: #212121;
    margin-bottom: 100px;
  }

  &__dep-wrapper {
    padding: 15px 0;
  }
}

@media screen and (max-width: 640px) {
  .departments {
    padding-top: 60px;

    &__header {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 480px) {
  .departments__dep-wrapper {
    padding: 40px 0;
  }
}