@use "styles/mixins/setFonts" as *;
@use "styles/variables/colors/colors";

.department {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 420px;
    margin-right: 30px;
  }

  &--reverse {
    flex-direction: row-reverse;
  }


  &__content {
    width: 50%;
    display: flex;
    justify-content: center;

    &-container {
      max-width: 430px;
    }
  }

  &__more-link {
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #1F5199;
  }

  &__header {
    @include setFonts("Roboto", 2.625rem, 2.75rem, 400);
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 40px;
  }

  &__text {
    @include setFonts("Roboto", 1rem, 1.5rem, 400);
    color: #2D2F30;
    max-width: 720px;
    margin-bottom: 40px;
  }

  &__link {
    color: colors.$iconSuccess;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #008060;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    &-text {
      @include setFonts("Roboto", 0.875rem, 1.25rem, 500);
      color: colors.$iconSuccess;
      padding: 0 20px;
    }
  }

  &__info {
    margin-top: 40px;
  }
}

.department--reverse {
  .department__img-wrapper {
    margin-right: 0;
    margin-left: 15px;
  }
}

@media screen and (max-width: 830px) {
  .department {
    flex-direction: column;

    &__header {
      min-width: 300px;
      margin: 0;
    }

    &__content {
      margin: 0 auto;
      width: 100%;

      &-container {
        padding: 0 30px;
        width: 100%;
        max-width: unset;
        display: flex;
        align-items: center;
      }

    }

    &__img-wrapper {
      margin: 0;
      min-width: auto;
    }

  }
}

@media screen and (max-width: 670px) {
  .department {
    &__header {
      margin-bottom: 30px;
      text-align: center;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
    }

    &__content {
      &-container {
        background-color: colors.$sectionPrimaryBg;
        flex-direction: column;
        border-radius: 12px;
        padding: 33px 15px;
      }
    }

    &__img-wrapper {
      display: none;
    }
  }
}

@media screen and (max-width: 350px) {
  .department {
    &__header {
      min-width: unset;
      font-size: 2rem;
      line-height: 2.25rem;
    }
  }
;
}